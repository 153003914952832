$white: #ffffff;

$theme-colors: (
    "light":      #7D74A3,
    "dark":       #030305,
    "primary":    #947fa6,
    "secondary":  #5c505f,
    "info":       #cfabba,
    "success":    #34a468,
    "warning":    #e0cc34,
    "danger":     #ec2b1c,
);